<template>
    <div
        data-scroll
        :data-scroll-id="name"
        :class="{ lightMode }"
        class="explore-container"
    >
        <div v-observer class="images-container">
            <div
                v-for="(item, index) in images"
                :key="index"
                data-scroll
                :data-scroll-speed="speedGenerator(index)"
                class="image-container "
            >
                <img :src="item.path" class="image" alt="" />
                <div class="bg-container"></div>
            </div>
        </div>
        <div
            v-observer
            data-scroll
            data-scroll-speed="1.7"
            class="description-container"
        >
            <p class="description">{{ firstLine }}</p>
            <p class="description">{{ secondLine }}</p>
            <p class="description">{{ lastLine }}</p>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
    props: {
        data: {
            type: Object,
            default: () => {}
        },
        config: {
            type: Object,
            default: () => {}
        },
        lightMode: {
            type: Boolean,
            default: false
        },
        name: {
            type: String,
            default: ""
        }
    },
    computed: {
        ...mapGetters({
            geDevice: "resize/geDevice"
        }),
        images() {
            return this.data?.list?.map(item => ({
                path: item?.image?.[0]?.devices[this.geDevice?.type]
            }));
        },
        header() {
            return this.config?.header?.title;
        },
        wordLength() {
            return this.header?.split(" ")?.length;
        },
        firstLine() {
            return this.header
                ?.split(" ")
                ?.slice(0, Math.floor(this.wordLength / 3) + 1)
                ?.join(" ");
        },
        secondLine() {
            return this.header
                ?.split(" ")
                ?.slice(
                    Math.floor(this.wordLength / 3) + 1,
                    2 * (Math.floor(this.wordLength / 3) + 1)
                )
                ?.join(" ");
        },
        lastLine() {
            return this.header
                ?.split(" ")
                ?.slice(
                    2 * (Math.floor(this.wordLength / 3) + 1),
                    this.wordLength
                )
                ?.join(" ");
        }
    },
    methods: {
        speedGenerator(index) {
            return this.geDevice.size < 769
                ? index + (index + 1) / 6
                : index + (index + 1) / 2;
        },
        mobileParallax() {
            if (this.geDevice.size < 1024) {
                let images = document.querySelectorAll(
                    ".explore-container .image-container"
                );
                new this.simpleParallax(images[0], {
                    customContainer: document.querySelector("body"),
                    overflow: true
                });
            }
        }
    },
    created() {
        this.$nextTick(() => {
            this.mobileParallax();
        });
    }
};
</script>

<style lang="scss" scoped>
$ease-out: cubic-bezier(0.15, 0.16, 0.37, 1);

.explore-container {
    position: relative;
    margin: 301px 195px 0;
    height: 1200px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;

    @media only screen and (max-width: 1650px) and (min-width: 1440px) {
        height: 845px;
        margin: 233px 165px 0;
    }
    @media only screen and (max-width: 1439px) and (min-width: 1366px) {
        height: 845px;
        margin: 232px 128px 0;
    }
    @media only screen and (max-width: 1365px) and (min-width: 1024px) {
        height: 700px;
        margin: 160px 47px 0;
    }
    @media only screen and (max-width: 1023px) and (min-width: 768px) {
        margin: 170px 47px 0;
        height: 723px;
    }
    @media only screen and (max-width: 767px) {
        margin: 144px 32px 0;
        height: 551px;
    }

    &.lightMode {
        .description {
            color: #0a141c;
        }

        .image-container {
            .image {
                opacity: 1 !important;
            }
        }
    }

    .description-container {
        &.observed {
            .description {
                transform: translateY(0);
            }
        }
    }

    .description {
        font-size: 55px;
        color: white;
        text-align: center;
        max-width: 1236px;
        position: relative;
        z-index: 2;
        transform: translateY(250px);
        transition: all 0.7s $ease-out;
        margin-top: 15px;
        @for $i from 1 through 3 {
            &:nth-child(#{$i}) {
                transition-delay: 0.15s + $i/10;
            }
        }

        @media only screen and (max-width: 1650px) and (min-width: 1366px) {
            font-size: 55px;
            transform: translateY(200px);
        }
        @media only screen and (max-width: 1365px) and (min-width: 768px) {
            font-size: 34px;
            transform: translateY(150px);
        }
        @media only screen and (max-width: 767px) {
            font-size: 21px;
            margin-bottom: 5px;
            transform: translateY(100px);
        }
        @media only screen and (max-width: 400px) {
            font-size: 20px;
        }
    }

    .images-container {
        position: absolute;
        width: 100%;
        height: 100%;

        &.observed {
            .image-container {
                clip-path: inset(0px 0px 0px) !important;

                .image {
                    transform: scale(1);
                }
            }
        }
    }

    .image-container {
        @include center-children;
        position: absolute;
        right: 0;
        width: 600px;
        overflow: hidden;
        clip-path: inset(0px 0px 40%);
        transition: clip-path 0.6s 0.2s $ease-out;
        bottom: 0;
        @media only screen and (max-width: 1650px) and (min-width: 1366px) {
            width: 380px;
        }
        @media only screen and (max-width: 1365px) and (min-width: 1024px) {
            width: 320px;
        }
        @media only screen and (max-width: 1023px) and (min-width: 768px) {
            width: 325px;
        }
        @media only screen and (max-width: 767px) {
            width: 200px;
        }

        .bg-container {
            background: #e5b676;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            opacity: 0.3;
        }

        &:first-child {
            left: 0;
            top: 0;
            right: unset;
            bottom: unset;
            @media only screen and (max-width: 1440px) {
                .bg-container {
                    display: none;
                }
            }
            @media only screen and (max-width: 767px) {
                width: 282px;
            }
        }

        &:nth-child(2) {
            clip-path: inset(60% 0px 0px);

            .bg-container {
                background: #740b43;
            }
        }

        .image {
            opacity: 0.3;
            transform: scale(1.3);
            transition: all 0.6s 0.2s $ease-out;
            width: 100%;
            position: relative;
            z-index: 1;
        }
    }
}
</style>
